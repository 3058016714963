import Cookies from 'js-cookie';
import { initDropdowns } from '/src/site/base/dropdown';
import {
    ADDITIONAL_COOKIE_PREFIX,
    COOKIE_POLICY_CONFIG,
    sendCookiesUpdatedEvent,
    setRootCookies,
} from '/src/components/b2c/cookie/cookie-commons';

const BUTTON_TYPE_ACCEPT_ALL = 'accept-all';
const BUTTON_TYPE_SAVE_CHANGES = 'save-changes';

let isInitialLoad = true;

function showButton(preferences) {
    const saveChangesButton = document.querySelector('.js-accept-custom-cookies', preferences);
    const acceptAllButton = document.querySelector('.js-accept-all-cookies', preferences);
    const rejectCookiesButton = document.querySelector('.js-accept-minimal-cookies', preferences);

    const cookieCheckboxes = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];
    const allUnchecked = cookieCheckboxes.every(cookie => !cookie.checked);
    const allChecked = cookieCheckboxes.every(cookie => cookie.checked);

    if (allUnchecked && isInitialLoad) {
        acceptAllButton.classList.remove('hidden');
        acceptAllButton.closest('.has-button').classList.remove('hidden');

        rejectCookiesButton.classList.remove('hidden');
        rejectCookiesButton.closest('.has-button').classList.remove('hidden');

        saveChangesButton.classList.add('hidden');
        saveChangesButton.closest('.has-button').classList.add('hidden');
    } else if (!isInitialLoad) {
        if (allChecked) {
            saveChangesButton.classList.remove('hidden');
            saveChangesButton.closest('.has-button').classList.remove('hidden');

            acceptAllButton.classList.add('hidden');
            acceptAllButton.closest('.has-button').classList.add('hidden');

            rejectCookiesButton.classList.remove('hidden');
            rejectCookiesButton.closest('.has-button').classList.remove('hidden');
        } else {
            saveChangesButton.classList.remove('hidden');
            saveChangesButton.closest('.has-button').classList.remove('hidden');

            acceptAllButton.classList.remove('hidden');
            acceptAllButton.closest('.has-button').classList.remove('hidden');

            rejectCookiesButton.classList.add('hidden');
            rejectCookiesButton.closest('.has-button').classList.add('hidden');
        }
    } else if (allChecked) {
        acceptAllButton.classList.remove('hidden');
        acceptAllButton.closest('.has-button').classList.remove('hidden');

        rejectCookiesButton.classList.remove('hidden');
        rejectCookiesButton.closest('.has-button').classList.remove('hidden');

        saveChangesButton.classList.add('hidden');
        saveChangesButton.closest('.has-button').classList.add('hidden');
    }
}

function applyCurrentCookies(preferences) {
    const currentStatus = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];
    const previousCookieValues = {};
    setRootCookies();

    currentStatus.forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const currentCookieId = `${ADDITIONAL_COOKIE_PREFIX + cookieId}`;
        previousCookieValues[currentCookieId] = Cookies.get(currentCookieId);
        cookieId && Cookies.set(currentCookieId, cookie.checked, COOKIE_POLICY_CONFIG);
    });

    sendCookiesUpdatedEvent(previousCookieValues);
    displayCookieNotification(preferences);

    showButton(preferences);
}

function acceptAllCookies(preferences) {
    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookie) => {
        cookie.checked = true;
    });
    applyCurrentCookies(preferences);
    displayCookieNotification(preferences);
}

function setMinimalCookies(preferences) {
    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookie) => {
        cookie.checked = false;
    });
    applyCurrentCookies(preferences);
}

function bindPreferencesEvents(preferences) {
    const saveChangesButton = document.querySelector('.js-accept-custom-cookies', preferences);
    const acceptAllButton = document.querySelector('.js-accept-all-cookies', preferences);
    const rejectCookiesButton = document.querySelector('.js-accept-minimal-cookies', preferences);

    acceptAllButton.addEventListener('click', (event) => {
        event.preventDefault();
        acceptAllCookies(preferences);

        showButton(preferences);
    });

    rejectCookiesButton.addEventListener('click', (event) => {
        event.preventDefault();
        setMinimalCookies(preferences);

        showButton(preferences);
    });

    saveChangesButton.addEventListener('click', (event) => {
        event.preventDefault();
        applyCurrentCookies(preferences);

        saveChangesButton.classList.add('hidden');
        saveChangesButton.closest('.has-button').classList.add('hidden');

        rejectCookiesButton.classList.remove('hidden');
        rejectCookiesButton.closest('.has-button').classList.remove('hidden');

        acceptAllButton.classList.remove('hidden');
        acceptAllButton.closest('.has-button').classList.remove('hidden');
    });

    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookieOption) => {
        cookieOption.onchange = () => {
            isInitialLoad = false;
            showButton(preferences);
        };
    });
}

function highlightSelectedCookies(preferences) {
    const cookieCheckboxes = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];

    cookieCheckboxes.forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const cookieRead = Cookies.get(`${ADDITIONAL_COOKIE_PREFIX + cookieId}`);
        if (cookieRead === undefined) {
            cookie.checked = true;
        } else {
            cookie.checked = cookieRead === 'true';
        }
    });

    showButton(preferences);
}

function displayCookieNotification(preferences) {
    const notificationMessage = preferences.getAttribute('data-cookie-notification-message');

    if (notificationMessage) {
        [...document.querySelectorAll('.js-cookie-notification-message')].forEach((element) => element.remove());

        const notificationMessageContainer = document.createElement('section');
        notificationMessageContainer.classList.add('cookie-notice-preferences-notification-message', 'cookie-notice--darkgrey', 'js-cookie-notification-message');
        notificationMessageContainer.innerHTML = notificationMessage;
        document.body.insertAdjacentElement('beforeend', notificationMessageContainer);

        setTimeout(() => {
            notificationMessageContainer.classList.add('hidden');
        }, 6000);
    }
}

function handlePageLoadButtons(preferences) {
    showButton(preferences);
}

function initCookiePreferences() {
    const preferences = document.querySelector('.js-cookie-preferences');
    isInitialLoad = true;
    highlightSelectedCookies(preferences);
    bindPreferencesEvents(preferences);
    handlePageLoadButtons(preferences);

    initDropdowns(preferences);
}

export { initCookiePreferences };
