import Cookies from 'js-cookie';

const ADDITIONAL_COOKIE_PREFIX = 'acceptCookiePolicy-';
const COOKIE_UPDATED_EVENT_NAME = 'daikin-cookies-updated';
const HAS_ACCEPTED_ADVERTISING_COOKIES_NAME = ADDITIONAL_COOKIE_PREFIX + 'marketing';
const COOKIE_POLICY_CONFIG = {
    secure: window.location.protocol === 'https:',
    expires: 9999,
};
const COOKIE_ACCEPTED_NAME = 'acceptCookies';
const COOKIE_POLICY_DATE_NAME = 'acceptCookiePolicyDate';
// const PAGE_TO_RETURN_NAME = 'pageToReturn';

function shouldResetCookies() {
    try {
        const cookiesAcceptedJson = Cookies.get(COOKIE_ACCEPTED_NAME);
        if (!cookiesAcceptedJson) {
            return true;
        }
        const cookiesAccepted = JSON.parse(cookiesAcceptedJson);
        const cookiesAcceptedTime = Cookies.get(COOKIE_POLICY_DATE_NAME);
        const updatedDate = document.querySelector('[data-cookie-policy-updated-date]');
        const policyUpdatedTime = updatedDate && updatedDate.dataset.cookiePolicyUpdatedDate;
        const policyUpdated = policyUpdatedTime && Number(policyUpdatedTime) > Number(cookiesAcceptedTime);

        return !cookiesAccepted || !cookiesAcceptedTime || policyUpdated;
    } catch (error) {
        console.error(error);
        return true;
    }
}

function setRootCookies() {
    const currentTime = new Date().getTime();

    Cookies.set(COOKIE_POLICY_DATE_NAME, currentTime, COOKIE_POLICY_CONFIG);
    Cookies.set(COOKIE_ACCEPTED_NAME, true, COOKIE_POLICY_CONFIG);
}

function isXfPage() {
    return document.body.classList.contains('xf-web-container');
}

function hasAcceptedCookie(cookieName) {
    return Cookies.get(cookieName) === 'true';
}

function hasAcceptedTargetCookie() {
    return hasAcceptedCookie(HAS_ACCEPTED_ADVERTISING_COOKIES_NAME);
}

function sendCookiesUpdatedEvent(previousCookieValues = {}) {
    const cookiesUpdatedEvent = new CustomEvent(COOKIE_UPDATED_EVENT_NAME, {detail: previousCookieValues});
    document.dispatchEvent(cookiesUpdatedEvent);
}

export {
    hasAcceptedTargetCookie,
    sendCookiesUpdatedEvent,
    setRootCookies,
    shouldResetCookies,
    isXfPage,
    ADDITIONAL_COOKIE_PREFIX,
    COOKIE_ACCEPTED_NAME,
    COOKIE_POLICY_CONFIG,
    COOKIE_POLICY_DATE_NAME,
    COOKIE_UPDATED_EVENT_NAME,
    HAS_ACCEPTED_ADVERTISING_COOKIES_NAME,
    // PAGE_TO_RETURN_NAME,
};
