import Cookies from 'js-cookie';
import { initDropdowns } from '/src/site/base/dropdown';

import {
    COOKIE_POLICY_CONFIG,
    ADDITIONAL_COOKIE_PREFIX,
    // PAGE_TO_RETURN_NAME,
    sendCookiesUpdatedEvent,
    setRootCookies,
    shouldResetCookies,
} from '/src/components/b2c/cookie/cookie-commons';

function removeAllCookies() {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((cookieName) => {
        cookieName.indexOf('acceptCookie') !== -1 && Cookies.remove(cookieName);
    });
}

function acceptMinimalModalCookies() {

    const modal = document.querySelector('.js-cookie-modal');
    const previousCookieValues = {};

    setRootCookies();

    [...document.querySelectorAll('.js-additional-cookie')].forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const currentCookieId = `${ADDITIONAL_COOKIE_PREFIX + cookieId}`;
        previousCookieValues[currentCookieId] = Cookies.get(currentCookieId);
        cookieId && Cookies.set(currentCookieId, false, COOKIE_POLICY_CONFIG);
    });

    sendCookiesUpdatedEvent(previousCookieValues);

    modal.classList.add('hidden');
}

function acceptAllModalCookies() {
    const modal = document.querySelector('.js-cookie-modal');
    const previousCookieValues = {};

    setRootCookies();

    [...document.querySelectorAll('.js-additional-cookie')].forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const currentCookieId = `${ADDITIONAL_COOKIE_PREFIX + cookieId}`;
        previousCookieValues[currentCookieId] = Cookies.get(currentCookieId);
        cookieId && Cookies.set(currentCookieId, true, COOKIE_POLICY_CONFIG);
    });

    sendCookiesUpdatedEvent(previousCookieValues);

    modal.classList.add('hidden');
}

// function manageCookies() {
//     const pageToReturn = window.location.href;

//     localStorage.setItem(PAGE_TO_RETURN_NAME, pageToReturn);
// }

function bindModalEvents(modal) {
    if(modal.querySelector('.js-accept-minimal-cookies')!=null){
        modal.querySelector('.js-accept-minimal-cookies').addEventListener('click', acceptMinimalModalCookies);
        }
    modal.querySelector('.js-accept-all-cookies').addEventListener('click', acceptAllModalCookies);
    // modal.querySelector('.js-manage-cookies').addEventListener('click', manageCookies);
}

function openCookieModal(modal) {
    if (!modal) {
        return;
    }

    modal.classList.remove('hidden');
    bindModalEvents(modal);
}

function initCookieModal() {
    const modal = document.querySelector('.js-cookie-modal');

    shouldResetCookies() && removeAllCookies();

    openCookieModal(modal);

    initDropdowns(modal);
}

export { initCookieModal };
